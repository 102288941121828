import React from "react"
import Helmet from "react-helmet"
import Layout from "../../components/layout"
import { Container, Row, Col } from "react-bootstrap"
import JumboPhoto from "../../components/jumbophoto"

export default () => <Layout>
	<Helmet title="Bankruptcy Law Practice | Gina Mcdonald &amp; Associates, L.L.C." defer={false} />

	<JumboPhoto/>

	<Container>
		<Row>
			<Col>
				<p>Have you or a loved been injured in a car accident or hurt on someone else's property? Have you been unable to work after being hurt on the job? If so, you likely have serious medical and financial concerns. You want to take care of your health concerns, but you are not sure if you can afford it, especially if you are missing work because of your injury. The Birmingham personal injury lawyers at Gina H. McDonald &amp; Associates may be able to help.</p>
				<p>If you or a loved one has been seriously injured in an accident, seek legal advice as soon as possible. <a href="../Contact.html">Contact our Alabama accident attorneys online</a> or call us toll free at 888-412-9047 to schedule an appointment to discuss your situation.</p>
				<h3>Skilled Representation in a Wide Range of Accident Cases</h3>
				
				<p>Every day, people are injured in all types of accidents. It doesn't matter where or when the accident occurred. If you were injured due to the negligent actions of others, we can help. <a href="../Attorneys/index.html">Our attorneys</a> provide skilled representation in a wide range of accident cases, including:</p>
				<p><a href="Car-Accidents.html"><strong>Car accidents</strong></a><strong>:</strong> If you or a loved one has been hurt while traveling along I-65, I-20, I-59 or another Alabama roadway, don't wait to get the help you need. We can help get to work on your claim right away.</p>
				<p><a href="Workers-Compensation.html"><strong>Workers' compensation</strong></a><strong>: </strong>If you have suffered an injury at work, you are eligible for workers' compensation. However, the amount of compensation is rarely enough to cover the full extent of your injuries. While workers' comp means that you cannot sue your employer, there may be third parties whose negligence contributed to the accident. We can help you pursue the compensation you deserve while holding all responsible parties accountable.</p>
				<p><a href="Slip-and-Fall-Accidents.html"><strong>Slip-and-fall accidents</strong></a><strong>:</strong> Owners of commercial property have a responsibility to maintain a safe and secure environment. If you were injured as the result of a slip-and-fall or were hurt due to a dangerous condition on the property of another, you may be entitled to financial compensation.</p>
				<p><strong>Medical malpractice:</strong> You go to your doctor to get better. So, what do you do when your physician's actions led to the worsening of your condition? Call us.</p>
				<p><strong>Product liability:</strong> Were you injured after using a product that malfunctioned? We can also help if the product that injured you was mislabeled.</p>
				<p><strong>Wrongful death:</strong> If you have lost a loved one following an accident, we can help your family pursue the proper compensation.</p><h3>Do Not Take a Settlement Without Getting Legal Advice</h3>
				<p>You may have received a settlement offer from the insurance company, but how can you be sure that you are getting a fair value? Litigation may not be necessary to protect your rights. We can review your case and negotiate with the insurance company to make sure you are getting appropriate coverage for your injury. If your insurance company is unwilling to take care of your medical needs, we can pursue litigation on your behalf. We will help you explore all of your options so that you can make the decision that is best for you and your family.</p>

				<p className="small text-muted">The following language is required pursuant to Rule 7.2, Alabama Rules of Professional Conduct. No representation is made that the quality of legal services to be performed is greater than the quality of legal services performed by other lawyers. This web site is designed for general information only. The information presented at this site should not be construed to be formal legal advice nor the formation of a lawyer/client relationship.</p>

			</Col>
			<Col md="4">
			<h2>Contact Alabama Wrongful Death Lawyers</h2>
				<p>If you have lost a loved one in an accident, or if you have suffered a serious injury as the result of a negligent act, you may be entitled to financial compensation. <a href="../Contact.html">Contact our Birmingham personal injury attorneys online</a> or call us toll free at 888-412-9047 to schedule an initial consultation to discuss your situation.</p>
			</Col>
		</Row>
	</Container>
</Layout>